<template>
  <div style="height: 100%;">
    <div class="page-wrapper" style="height: 100%;" id="sandbox">
      <div
        class="mypassage"
        style="overflow: auto;"
        v-if="myPassageContent"
        data-simplebar="init"
      >
        <div @mouseup="getSelectText" v-html="myPassageContent"></div>
      </div>
      <div class="myquestion">
        <p>
          <span class="myquestion-no">
            <b>{{ questionOrder }}. </b>
          </span>
          <span v-html="content"></span>
        </p>
        <div
          class="form-check"
          v-for="(option, optionIndex) in options"
          :key="option.order"
        >
          <div
            :class="
              question.maskArr.indexOf(option.letter) > -1
                ? 'form-check-hide'
                : ''
            "
          >
            <input
              class="form-check-input"
              type="radio"
              :name="questionOrder"
              :id="optionIndex"
              :value="option.label"
              :checked="myAnswer == option.label"
              :disabled="question.crossOutArr.indexOf(option.label) > -1"
            />
            <label
              :class="
                question.crossOutArr.indexOf(option.label) > -1
                  ? 'strike form-check-label elim-option'
                  : 'form-check-label elim-option'
              "
              :for="optionIndex"
              @click="onChangeOption(option.label)"
            >
              {{ getLetter(questionOrder, optionIndex) }}.
              <span v-html="option.content"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import $ from "jquery";

export default {
  metaInfo() {
    return {
      title: "ACT - ACT Academy"
    };
  },

  components: {},

  mixins: [],

  props: {
    canHighlighter: {
      type: Boolean,
      default: false
    },
    canEliminator: {
      type: Boolean,
      default: false
    },
    canMasking: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "test",
      validator: value => ["test", "explanation"].includes(value)
    },
    questionOrder: {
      type: Number,
      default: null
    },
    content: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    answer: {
      type: String,
      default: ""
    },
    passageIntro: {
      type: String,
      default: ""
    },
    passageContent: {
      type: String,
      default: ""
    },
    correctAnswer: {
      type: String,
      default: ""
    },
    explanation: {
      type: String,
      default: ""
    },
    isCorrect: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      question: {
        crossOutArr: [],
        maskArr: []
      },
      myPassageContent: null,
      myAnswer: null
    };
  },
  computed: {},
  watch: {
    currentZoom() {
      $(".wrapper").animate({ zoom: this.currentZoom }, "slow");
    },
    questionOrder() {
      this.myAnswer = this.answer;
    },
    answer(value) {
      this.myAnswer = JSON.parse(JSON.stringify(value));
    },
    passageContent(value) {
      console.log(value);
      this.myPassageContent = JSON.parse(JSON.stringify(value));
    }
  },

  mounted() {
    this.myAnswer = JSON.parse(JSON.stringify(this.answer));
    this.myPassageContent = JSON.parse(JSON.stringify(this.passageContent));
  },

  methods: {
    reSetPassage() {
      // console.log(this.myPassageContent);
      // this.myPassageContent = JSON.parse(JSON.stringify(this.passageContent));
      $("span.highlight").each(function() {
        $(this).removeClass("highlight");
      });
    },
    getLetter(questionOrder, index) {
      const oddLetters = ["A", "B", "C", "D", "E"];
      const evenLetters = ["F", "G", "H", "J", "K"];
      if (questionOrder % 2 === 1) {
        return oddLetters[index];
      } else {
        return evenLetters[index];
      }
    },
    getSelectText() {
      if (this.canHighlighter) {
        this.replaceSelection();
      }
    },
    replaceSelection() {
      if (window.getSelection) {
        let sel = window.getSelection();
        let selectStr = sel.toString();
        let ranges = [];
        if (selectStr.trim != "") {
          for (var i = 0; i < sel.rangeCount; i++) {
            ranges[i] = sel.getRangeAt(i);
          }
          let range = sel.getRangeAt(0);
          let temp = "";
          range.deleteContents();
          for (let i = 0; i < selectStr.length; i++) {
            console.log([selectStr[selectStr.length - 1 - i], selectStr[i]]);
            let text = selectStr[selectStr.length - 1 - i];
            if (text === " ") {
              text = "&nbsp;";
            }
            temp = `<span class="highlight-span highlight">${text}</span>`;
            range.insertNode($(temp)[0]);
          }
        }
      } else if (document.selection) {
        //ie
        let sel = document.selection.createRange();
        sel.select();
        let selectStr = sel.text;
        sel.pasteHTML("<b>" + selectStr + "<b/>");
      }
    },
    onChangeOption(letter) {
      this.$emit("onChangeAnswer", letter);
      if (this.canEliminator) {
        let index = this.question.crossOutArr.indexOf(letter);
        if (index === -1) {
          this.question.crossOutArr.push(letter);
        }
        if (index > -1) {
          this.question.crossOutArr.splice(index, 1);
        }
      }
      if (this.canMasking) {
        let index = this.question.maskArr.indexOf(letter);
        if (index === -1) {
          this.question.maskArr.push(letter);
        }
        if (index > -1) {
          this.question.maskArr.splice(index, 1);
        }
      }
    }
  }
};
</script>

<style>
.highlight {
  background-color: #fae89d;
  border-bottom: 1px dashed #000;
  /* position: unset;
  display: inline-block; */
}
.highlight-span {
  position: unset;
  display: inline-block;
}
</style>
<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

.page-wrapper {
  display: flex;
}
.mypassage {
  width: 56%;
  padding: 1rem;
}
::v-deep .mypassage,
::v-deep .mypassage p {
  word-wrap: break-word;
}
.myquestion {
  flex: 1;
  padding: 1rem;
}
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}
.sidebar-wrapper {
  height: 100% !important;
}
.form-check {
  position: relative;
  min-height: 2rem;
}
.optionActionLine {
  position: absolute;
  display: block;
  height: 0.1rem;
  background: #000000;
  width: 104%;
  left: -2%;
}
.myquestion .form-check-input,
.myquestion .form-check-label {
  font-size: 14px;
  cursor: pointer;
}

.strike {
  text-decoration: line-through;
  color: #6c757d;
}
.form-check-hide {
  color: white;
}
.form-check-hide .strike {
  color: white;
}
@media (max-width: 768px) {
  .page-wrapper {
    display: block;
  }
  .mypassage {
    width: 100%;
    height: 55%;
    border: 1px solid #ccc;
    background: #fafafa;
  }
  .myquestion {
    height: 50%;
    overflow: auto;
  }
  .form-check {
    margin: 1rem 0;
  }
}

</style>
<style scoped src="@/views/act/assets/css/act.css"></style>

<style scoped src="@/views/act/assets/css/simplebar.css"></style>
